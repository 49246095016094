import React from 'react'
import { graphql } from 'gatsby'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import UsVsThem from '~/components/UsVsThem'

const UsVsThemPage = ({ data }) => {
  const { page } = data

  return page ? (
    <Layout>
      <Metadata title={page.name} />
      <UsVsThem page={page} />
    </Layout>
  ) : null
}

export default UsVsThemPage

export const query = graphql`
  query UsVsThemPage($locale: String) {
    page: contentfulPage(
      slug: { eq: "us-vs-them" }
      node_locale: { eq: $locale }
    ) {
      ...ContentPageFragment
    }
  }
`
