/**jsx @jsx */
import React from 'react'
import { Box } from 'theme-ui'
const GraphLine = ({ point, forwardSx }) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '2px',
        backgroundColor: 'governorBay',
        color: 'governorBay',
        position: 'relative',
        zIndex: 1,
        ':before': {
          content: `"${point}"`,
          position: 'absolute',
          top: '50%',
          left: ['-5px', '-16px'],
          transform: 'translate(-100%, -50%)',
          fontSize: ['10px', 4]
        },
        ...forwardSx
      }}
    />
  )
}
export default GraphLine
